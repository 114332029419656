<template>
  <div class="mb-3">
    <ERTPLocationInput
      :labelText="locationLabelText"
      :placeholder="placeholder"
      :helpText="helpText"
      :readOnly="readOnly"
      :model-value="location"
      @update:model-value="onUpdateLocation"
      :gpid="gpid"
      @update:gpid="onUpdateGooglePlaceId"
    >
    <VueDatePicker 
      model-value="dateTime"
      @update:model-value="onUpdateDateTime"
      v-uid ref="datePicker"
      :start-time="startTime"
      utc="preserve"
      :timezone="timeZone"
      :name="datePickerName"
      :aria-labels="{input: datePickerName}"
      :enable-time-picker=false
      :auto-apply=true
      :week-start="weekStart"
      :readonly="readOnly"
      :disabled="readOnly"
      >
      <template #trigger>
        <CalendarButton
          :title="dateLabelText"
          :aria-label="dateLabelText"
          :disabled="readOnly"
        />
      </template>
    </VueDatePicker>
    </ERTPLocationInput>
  </div>
</template>


<script setup lang="ts">
import { clientOnly } from 'vike-vue/clientOnly'
const VueDatePicker = clientOnly(() => import('@vuepic/vue-datepicker'));
import ERTPLocationInput from "../input/ERTPLocationInput.vue";
import { ERTPLocation } from "../input/ERTPLocationInput.vue";
import CalendarButton from "../buttons/CalendarButton.vue";
import { ref } from 'vue';
import { getWeekStart } from '../../utilities/weekStart';
const weekStart = getWeekStart();

defineProps<{
  locationLabelText: string,
  dateLabelText: string,
  placeholder: string,
  helpText: string,
  location: ERTPLocation,
  dateTime?: string,
  startTime: { hours: number, minutes: number },
  timeZone?: string,
  datePickerName: string,
  datePickerPlaceholder: string,
  datePickerHelpText: string,

  // TODO(tjohns): Can we factor these out?
  readOnly?: boolean
  gpid?: string,
}>();

const emit = defineEmits<{
  'update:location': [location: ERTPLocation],
  'update:gpid': [gpid: string],
  'update:dateTime': [dateTime: string]
}>();


function onUpdateLocation(location: ERTPLocation) {
  emit("update:location", location);
}

function onUpdateGooglePlaceId(gpid: string) {
  emit("update:gpid", gpid);
}

function onUpdateDateTime(dateTime: string) {
  emit("update:dateTime", dateTime);
}

const datePicker = ref();
</script>
