import { computed } from "vue";
import { useRoadtrips } from "./roadtrips";
import { useProfile } from "./profile";

export function useRoadtripPolicy() {
  const { roadtrips } = useRoadtrips();
  const { profile } = useProfile();

  return {
    hasRoomForNewRoadtrip: computed(() => {
      if(!roadtrips.value?.length) return true; // If they haven't got any road trips, let them create one
    
      // Note we'll override BOTH undefined AND 0 to be 1 - the side-effect is that setting the max to 
      // zero doesn't disable road trip creation
      const maxAllowedRoadtrips = profile.value?.maxAllowedRoadtrips || 1;
    
      return (roadtrips.value.length < maxAllowedRoadtrips);
    })
  }

}

